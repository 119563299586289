<template>
  <v-row v-if="state==='billing'" dense class="mx-2">
    <v-col cols="12" class="my-0 py-0">
      <template>
        <v-row class="mt-0">
          <v-col class="d-flex justify-end align-center" cols="12">
            <span class="d-flex align-start font-weight-bold font-size12-5">CPT</span>
            <v-progress-circular
                class="font-size12 mx-2 font-weight-bold"
                :rotate="-90"
                :size="75"
                :width="3"
                :value="billingSummary.cpt99453"
                :color=checkColor(billingSummary.cpt99453)
            >
              <div class="d-flex flex-column">
                <span>99453</span>
                <div>
                  {{ billingSummary.cpt99453 }}%
                </div>
              </div>
            </v-progress-circular>
            <v-progress-circular
                class="font-size12 mx-2 font-weight-bold"
                :rotate="-90"
                :size="75"
                :width="3"
                :value="billingSummary.cpt99454"
                :color=checkColor(billingSummary.cpt99454)
            >
              <div class="d-flex flex-column">
                <span>99454</span>
                <div>
                  {{ billingSummary.cpt99454 }}%
                </div>
              </div>
            </v-progress-circular>
            <v-progress-circular
                class="font-size12 mx-2 font-weight-bold"
                :rotate="-90"
                :size="75"
                :width="3"
                :value="billingSummary.cpt99457"
                :color=checkColor(billingSummary.cpt99457)
            >
              <div class="d-flex flex-column">
                <span>99457</span>
                <div>
                  {{ billingSummary.cpt99457 }}%
                </div>
              </div>
            </v-progress-circular>
            <v-progress-circular
                class="font-size12-5 mx-2 font-weight-bold"
                :rotate="-90"
                :size="75"
                :width="3"
                :value="billingSummary.cpt994581st"
                :color=checkColor(billingSummary.cpt994581st)
            >
              <div class="d-flex flex-column">
                <span> 99458 1st</span>
                <div>
                  {{ billingSummary.cpt994581st }}%
                </div>
              </div>
            </v-progress-circular>
            <v-progress-circular
                class="font-size12 mx-2 font-weight-bold"
                :rotate="-90"
                :size="75"
                :width="3"
                :value="billingSummary.cpt994582nd"
                :color=checkColor(billingSummary.cpt994582nd)
            >
              <div class="d-flex flex-column">
                <span>99458 2nd</span>
                <div>
                  {{ billingSummary.cpt994582nd }}%
                </div>
              </div>
            </v-progress-circular>
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-col class="px-0 pb-0" cols="12">
      <v-progress-linear  :indeterminate="loading" v-if="loading"></v-progress-linear>
      <v-data-table
          :loading="false"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :items="filteredBillingsFromRepo"
          item-key="patientId"
          fixed-header
          dense
          :single-select="true"
          class="mx-0 px-0 medical-staff-data-table"
          :height="pageHeight(75)"
          group-by="hospitalData.hospitalName"
          :expanded.sync="expanded"
          :single-expand="true"
          :footer-props="footerProps"
          :items-per-page="-1"
      >
        <template  v-slot:[`group.header`]="{ headers, items, toggle, group, isOpen}">
          <td @click="toggle" class="elevation-1 px-1 py-1 white-background" style="position:sticky; top:0; z-index:1" :colspan="8">
            <v-row class="px-0" dense>
              <v-col class="text-left">
                <span class="table-font-size mt-1" v-if="items && items.length > 0">
                  {{items[0]?.hospitalData?.hospitalName}}
                </span>
              </v-col>
              <v-col cols="9" align-self="center" class="py-0">
                <v-row dense>
                  <v-col class="px-0 d-flex align-center justify-center py-0">
                    <v-progress-linear
                        v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                        class="width150 font-weight-bold font-size12"
                        :height="20"
                        rounded
                        :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99453"
                        :color="getHospitalSummary(items[0]?.hospitalData?.id).percent99453 >= 100 ? 'green' : 'primary'"
                        :class="getHospitalSummary(items[0]?.hospitalData?.id).percent99453 >= 100 ? 'white--text' : ''"
                        background-color="gray"
                    >
                      99453 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99453 }}%
                    </v-progress-linear>
                    <!--                </div>-->
                  </v-col>
                  <v-col class="px-0 d-flex align-center justify-center py-0">
                    <v-progress-linear
                        v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                        class="width150 font-weight-bold font-size12"
                        :height="20"
                        rounded
                        :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99454"
                        :color="getHospitalSummary(items[0]?.hospitalData?.id).percent99454 >= 100 ? 'green' : 'primary'"
                        :class="getHospitalSummary(items[0]?.hospitalData?.id).percent99454 >= 100 ? 'white--text' : ''"
                        background-color="gray"
                    >
                      99454 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99454 }}%
                    </v-progress-linear>
                  </v-col>
                  <v-col class="px-0 d-flex align-center justify-center py-0"  >
                    <v-progress-linear
                        v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                        class="width150 font-weight-bold font-size12"
                        :height="20"
                        rounded
                        :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99457"
                        :color="getHospitalSummary(items[0]?.hospitalData?.id).percent99457 >= 100 ? 'green' : 'primary'"
                        :class="getHospitalSummary(items[0]?.hospitalData?.id).percent99457 >= 100 ? 'white--text' : ''"
                        background-color="gray"
                    >
                      99457 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99457 }}%
                    </v-progress-linear>
                  </v-col>
                  <v-col class="px-0 d-flex align-center justify-center py-0"  >
                    <v-progress-linear
                        v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                        class="width150 font-weight-bold font-size12"
                        :height="20"
                        rounded
                        :value="getHospitalSummary(items[0]?.hospitalData?.id).percent994581st"
                        :color="getHospitalSummary(items[0]?.hospitalData?.id).percent994581st >=100 ? 'green' : 'primary'"
                        :class="getHospitalSummary(items[0]?.hospitalData?.id).percent994581st >= 100 ? 'white--text' : ''"
                        background-color="gray"
                        >
                      99458 1st {{ getHospitalSummary(items[0]?.hospitalData?.id).percent994581st }}%
                    </v-progress-linear>
                  </v-col>
                  <v-col class="px-0 d-flex align-center justify-center py-0"  >
                    <v-progress-linear
                        v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                        class="width150 font-weight-bold font-size12"
                        :height="20"
                        rounded
                        :value="getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd"
                        :color="getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd >=100 ? 'green' : 'primary'"
                        :class="getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd >=100 ? 'white--text' : ''"
                        background-color="gray"
                    >
                      99458 2nd {{ getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd }}%
                    </v-progress-linear>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" class="text-right py-0">
<!--               <v-icon @click.stop="generateBillingDataForHospitalAdmin(items[0].hospitalData.id)"  size="19" color="primary" >mdi-clipboard-text</v-icon>-->
                <v-btn class="my-0 " small icon :ref="group" :data-open="isOpen">
                  <v-icon size="20" v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                  <v-icon size="20" v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                </v-btn>
                <!--                </div>-->
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item="{ item, group, isOpen }">
          <tr  @click="expand(item)">
            <td class="px-0 text-left">
                <span class="table-font-size ml-5">{{item.patientData?.firstName}} {{item.patientData?.lastName}}</span>
            </td>
            <td class="px-0">
              <div class="d-flex justify-center">
                <v-progress-linear
                    rounded
                    :height="20"
                    :value="item.cptCode99453?.status === 1 ? 100 : 0"
                    :color="item.cptCode99453?.status === 1 ? 'green' : 'primary'"
                    :class="item.cptCode99453?.status === 1 ? 'white--text' : ''"
                    background-color="gray"
                    class="width150 font-size12"
                >
                  <template>
                    <v-icon size="16" class="mr-2 " v-if="item.cptCode99453?.status === 1" color="black">mdi-checkbox-blank</v-icon>
                    <v-icon size="16" class="mr-2 " v-else color="black" >mdi-checkbox-blank-outline</v-icon>
                  </template>
                  99453
                </v-progress-linear>
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-progress-linear
                    rounded
                    :height="20"
                    :value="item.myBillings?.percent99454"
                    :color="item.myBillings?.percent99454 === 100 ? 'green' : 'primary'"
                    :class="item.myBillings?.percent99454 === 100 ? 'white--text' : ''"
                    background-color="gray"
                    class="width150 font-size12"
                >
                  <template>
                    <v-icon size="16" class="mr-2 " v-if="item.cptCode99454?.status === 1" color="black">mdi-checkbox-blank</v-icon>
                    <v-icon size="16" class="mr-2 " v-else color="black" >mdi-checkbox-blank-outline</v-icon>
                  </template>
                  99454
                </v-progress-linear>
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-progress-linear
                    rounded
                    :height="20"
                    :value="item.myBillings?.percent99457"
                    :color="item.myBillings?.percent99457 === 100 ? 'green' : 'primary'"
                    :class="item.myBillings?.percent99457 === 100 ? 'white--text' : ''"
                    background-color="gray"
                    class="width150 font-size12"
                >
                  99457 {{ secToMinV2(item.myBillings?.seconds99457) }}
                </v-progress-linear>
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-progress-linear
                    rounded
                    :height="20"
                    :value="item.myBillings?.percent994581st"
                    :color="item.myBillings?.percent994581st === 100 ? 'green' : 'primary'"
                    :class="item.myBillings?.percent994581st === 100 ? 'white--text' : ''"
                    background-color="gray"
                    class="width150 font-size12"
                >
                  99458 1st {{ secToMinV2(item.myBillings?.seconds994581st) }}
                </v-progress-linear>
              </div>
            </td>
            <td>
              <div class="d-flex  justify-center">
                <v-progress-linear
                    rounded
                    :height="20"
                    :value="item.myBillings?.percent994582nd"
                    :color="item.myBillings?.percent994582nd === 100 ? 'green' : 'primary'"
                    :class="item.myBillings?.percent994582nd === 100 ? 'white--text' : ''"
                    background-color="gray"
                    class="width160 font-size12"
                >
                  99458 2nd {{ secToMinV2(item.myBillings?.seconds994582nd) }}
                </v-progress-linear>
              </div>
            </td>
            <td class="text-right">
<!--              <v-icon @click.stop="patientDataBilling(item.patientId)"  size="19" color="primary" >mdi-clipboard-text</v-icon>-->
              <v-btn small icon :ref="group" :data-open="isOpen">
                <v-icon size="20" v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                <v-icon size="20" v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <template v-if="item.id !== -1">
            <td class="py-2 billings-border-bottom" colspan="8">
              <DetailsCard v-if="expanded" :item="item"></DetailsCard>
            </td>
          </template>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
  <v-row v-else-if="state==='email'">
   <BillingReports></BillingReports>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DetailsCard from '../components/BillingDetailsCard.vue';
import { billWhite } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
import { aggregateData } from '@/utils/aggregateData';
import BillingReports from '../components/BillingReports.vue';

export default {
  components: {
    BillingReports,
    DetailsCard,
  },
  data () {
    return {
      state: 'billing',
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      loading: false,
      search: '',
      expanded: [],
      date: new Date().toISOString().substr(0, 7),
      invoice: billWhite,
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      hospitalData: [],
    };
  },
  computed: {
    ...mapState({
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
      loggedUser: (state) => state.authentication.userData,
      authUserData: (state) => state.authentication.userData,
      generatedCsvFile: (state) => state.billings.generatedCsvFile,
      billingReportState: (state) => state.filterbar.billingReportState,
    }),
    ...mapGetters({
      roleName: 'authentication/getRole',
      hospitalId: 'authentication/getHospitalId',
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
      appBarBillingDate: 'filterbar/getBillingDate',
      appBarBillingDataForHospitalAdmin: 'filterbar/getBillingDataForHospitalAdmin',
      appBarBillingDataOrganizationalAdmin: 'filterbar/getBillingDataOrganizational',
      appBarBillingCsv: 'filterbar/getBillingCsv',
    }),
    filteredBillingsFromRepo () {
      const searchTerm = this.appBarSearch ? this.appBarSearch.toLowerCase() : '';
      return this.billingsFromRepo.filter(item => {
        const firstName = item.patientData && item.patientData.firstName ? item.patientData.firstName.toLowerCase() : '';
        const lastName = item.patientData && item.patientData.lastName ? item.patientData.lastName.toLowerCase() : '';
        const hospital = item.hospitalData?.hospitalName.toLowerCase();

        return firstName.includes(searchTerm) || lastName.includes(searchTerm) || hospital.includes(searchTerm);
      });
    },
    searchResults () {
      const searchTerm = this.search ? this.search.toLowerCase() : '';
      return this.billingsFromRepo.filter(item => {
        const firstName = item.patientData && item.patientData.firstName ? item.patientData.firstName.toLowerCase() : '';
        const lastName = item.patientData && item.patientData.lastName ? item.patientData.lastName.toLowerCase() : '';
        const healthInsuranceProgramData = item.healthInsuranceProgramData ? item.healthInsuranceProgramData.toLowerCase() : '';

        return firstName.includes(searchTerm) || lastName.includes(searchTerm) || healthInsuranceProgramData.includes(searchTerm);
      });
    },
    calculateHeight () {
      const windowHeight = window.innerHeight;
      const breakpointPercentage = {
        xs: 30,
        sm: 80.5,
        md: 70,
        lg: 68.8,
        xl: 69.0,
      };
      const breakpointName = this.$vuetify.breakpoint.name;
      const percentage = breakpointPercentage[breakpointName];

      if (percentage !== undefined) {
        const calculatedHeight = (windowHeight * percentage) / 100;
        return `${calculatedHeight}px`;
      } else {
        return '80%'; // Default percentage value
      }
    },
    // headers () {
    //   return [
    //     { text: this.$t('patient-name'), value: 'patientName', sortable: true, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     { text: '', value: '', sortable: false, class: 'color-header' },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: '', value: 'Insurance', sortable: false },
    //     // { text: 'CptCode99454', value: 'cptCode99454', sortable: false },
    //     // { text: 'CptCode99457', value: 'cptCode99457', sortable: false },
    //     // { text: 'CptCode994581st', value: 'cptCode994581st', sortable: false },
    //     // { text: 'CptCode994582nd', value: 'cptCode994582nd', sortable: false },
    //   ];
    // },
  },
  watch: {
    billingReportState (val) {
      this.state = val;
    },
    date (val) {
      const month = val.slice(-2);
      this.initialize({
        month: month.charAt(0) === '0' ? month.charAt(1) : parseInt(month),
        year: parseInt(val.substring(0, 4)),
      });
    },
    async appBarBillingDate (val) {
      // after filtering
      if (val !== null && val !== undefined) {
        const monthExtarcted = parseInt(val.slice(-2));
        const yearExtarcted = parseInt(val.substring(0, 4));

        await this.initialize({
          month: monthExtarcted,
          year: yearExtarcted,
        });
      }
    },
    appBarBillingDataForHospitalAdmin (val) {
      this.generateBillingDataForHospitalAdmin(val.hospitalId);
    },
    appBarBillingDataOrganizationalAdmin (val) {
      this.generateBillingDataForOrganizationalAdmin(val);
    },
    appBarBillingCsv (val) {
      this.generateCSV(val);
    },
  },
  async mounted () {
    await this.initialize({
      month: parseInt(this.date.substring(5, 7)),
      year: parseInt(this.date.substring(0, 4)),
    });
  },
  methods: {
    resetLocalProperties () {
      this.billingSummary = {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      };
      this.hospitalSummary = {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      };
      this.hospitalData = [];
    },
    getHospitalSummary (hospitalId) {
      // Check if the ID exists in the aggregatedData
      if (hospitalId in this.hospitalData) {
        // Return the aggregated data for the specified ID
        return this.hospitalData[hospitalId];
      } else {
        // Return null or handle the case when the ID is not found
        return null;
      }
    },
    async getMedicalInstitutions () {
      this.loading = true;
      await this.$store
          .dispatch('medicalInstitutions/getMedicalInstitutions')
          .then(() => {
            // this.medicalInstitutions = this.medicalInstitutionsFromRepo;
            this.loading = false;
          });
    },
    async generateCSV (paramBody) {
      // const dateParts = this.date.split('-');
      // const year = parseInt(dateParts[0], 10);
      // const month = parseInt(dateParts[1], 10);
      // const currentYear = new Date().getFullYear();
      // const currentMonth = new Date().getMonth() + 1;

      // const dateParts = this.date.split('-');
      const year = paramBody.selectedYear;
      const month = paramBody.selectedMonth;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      const selectedYear = year;
      const selectedMonth = month;
      var clientName = paramBody.clientName;
      var clientId = paramBody.clientId;

      if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth)) {
        showErrorAlert(this.$t('cant-download-csv-for-upcoming-months'));
        return;
      }

      if (this.roleName !== 'ORGANIZATION_ADMIN') {
        clientName = this.authUserData.hospitalAssignedData.hospitalName;
        clientId = this.authUserData.hospitalAssignedData.id;

        const hospitalData = { hospitalId: clientId, month: selectedMonth, year: selectedYear };
        await this.$store.dispatch('billings/generateCsvHospitalPatientsForMonth', hospitalData)
            .then(res => {
              showSuccessAlert(this.$t('csv-downloaded'));
              const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
              const link = document.createElement('a');
              const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
              link.href = url;
              link.setAttribute('download', csvFileName);
              document.body.appendChild(link);
              link.click();
            });
      } else {
        clientName = this.authUserData.organizationAssignedData.institutionName;
        clientId = this.authUserData.organizationAssignedData.id;

        const organizationData = { organizationId: clientId, month: selectedMonth, year: selectedYear };
        await this.$store.dispatch('billings/generateCsvOrganizationPatientsForMonth', organizationData)
            .then(res => {
              showSuccessAlert(this.$t('csv-downloaded'));
              const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
              const link = document.createElement('a');
              const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
              link.href = url;
              link.setAttribute('download', csvFileName);
              document.body.appendChild(link);
              link.click();
            });
      }
    },
    checkColor (value) {
      if (value >= 0 && value <= 25) {
        return 'red';
      }
      if (value > 25 && value <= 50) {
        return 'orange';
      }
      if (value > 50 && value <= 75) {
        return 'blue';
      }
      if (value > 75 && value <= 95) {
        return 'teal';
      }
      if (value > 95 && value <= 100) {
        return 'green';
      }
    },
    secToMin (seconds) {
      var convert = function (x) {
        return (x < 10) ? '0' + x : x;
      };
      return convert(parseInt(seconds / (60 * 60))) + ' h ' +
          convert(parseInt(seconds / 60 % 60)) + ' min ' +
          convert(seconds % 60) + ' sec';
    },
    secToMinV2 (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
    },
    closeAll () {
      Object.keys(this.$refs).forEach(k => {
        if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
          this.$refs[k].$el.click();
        }
      });
    },
    patientDataBilling (id) {
      const month = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(5, 7) : this.date.substring(5, 7);
      const year = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(0, 4) : this.date.substring(0, 4);

      const dataForPatientBilling = {
        patientid: id,
        month: parseInt(month),
        year: parseInt(year),
      };

      this.$store.dispatch('billings/generateforpatientformonth', dataForPatientBilling);
    },
    async generateBillingDataForHospitalAdmin (item) {
      const month = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(5, 7) : this.date.substring(5, 7);
      const year = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(0, 4) : this.date.substring(0, 4);

      const dataForBilling = {
        hospitalId: item,
        month: parseInt(month),
        year: parseInt(year),
      };

      await this.$store.dispatch('billings/generateforallhospitalpatientsformonth', dataForBilling);
      await this.initialize(dataForBilling);
    },
    async generateBillingDataForOrganizationalAdmin (item) {
      const month = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(5, 7) : this.date.substring(5, 7);
      const year = this.appBarBillingDate !== null ? this.appBarBillingDate.substring(0, 4) : this.date.substring(0, 4);

      const dataForBillingOrganizationAdmin = {
        organizationId: this.authUserData.organizationId,
        month: parseInt(month),
        year: parseInt(year),
      };

      await this.$store.dispatch('billings/generateforallorganizationpatientsformonth', dataForBillingOrganizationAdmin);
      await this.initialize(dataForBillingOrganizationAdmin);
    },
    async initialize (object) {
      this.resetLocalProperties();
      if (this.roleName === 'ORGANIZATION_ADMIN') {
        const headers = {
          organizationId: this.authUserData.organizationId,
          month: object.month,
          year: object.year,
        };
        this.loading = true;
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
          var patientNumber = this.billingsFromRepo.length;

          this.billingSummary.cpt99453 = 0;
          this.billingSummary.cpt99454 = 0;
          this.billingSummary.cpt99457 = 0;
          this.billingSummary.cpt994581st = 0;
          this.billingSummary.cpt994582nd = 0;

          if (patientNumber > 0) {
            this.billingsFromRepo.forEach((patient, index) => {
              if (patient.cptCode99453?.status === 1) {
                this.billingSummary.cpt99453++;
              }
              if (patient.cptCode99454?.status === 1) {
                this.billingSummary.cpt99454++;
              }
              if (patient.cptCode99457?.status === 1) {
                this.billingSummary.cpt99457++;
              }
              if (patient.cptCode99458First?.status === 1) {
                this.billingSummary.cpt994581st++;
              }
              if (patient.cptCode99458Second?.status === 1) {
                this.billingSummary.cpt994582nd++;
              }

              let percent99454 = 0;
              let totalMeasurements99454 = 0;
              let measurement99454IsBillable = false;
              let measurement99457IsBillable = false;
              let measurement994581stIsBillable = false;
              let measurement994582ndIsBillable = false;
              if (patient.cptCode99454 !== null) {
                if (patient.cptCode99454.totalMeasurements < 16) {
                  percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                } else {
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                  percent99454 = 100;
                  measurement99454IsBillable = true;
                }
              }

              const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

              let percent99457 = 0;
              let percent994581st = 0;
              let percent994582nd = 0;

              let seconds99457 = 0;
              let seconds994581st = 0;
              let seconds994582nd = 0;

              if (totalSec < 1200) {
                seconds99457 = totalSec;
                percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

                seconds994581st = 0;
                percent994581st = 0;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 1200 && totalSec < 2400) {
                seconds99457 = 1200;
                percent99457 = 100;
                measurement99457IsBillable = true;

                seconds994581st = totalSec - 1200;
                percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 2400 && totalSec < 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;
                measurement994581stIsBillable = true;

                seconds994582nd = totalSec - 2400;
                percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
              }

              if (totalSec >= 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;

                seconds994582nd = totalSec - 2400;
                percent994582nd = 100;
                measurement994582ndIsBillable = true;
              }

              const myBillings = {
                totalMeasurements99454: totalMeasurements99454,
                percent99454: percent99454,
                percent99457: percent99457,
                percent994581st: percent994581st,
                percent994582nd: percent994582nd,
                seconds99457: seconds99457,
                seconds994581st: seconds994581st,
                seconds994582nd: seconds994582nd,
                m99454IsBillable: measurement99454IsBillable,
                m99457IsBillable: measurement99457IsBillable,
                m994581stIsBillable: measurement994581stIsBillable,
                m994582ndIsBillable: measurement994582ndIsBillable,
              };
              patient.myBillings = myBillings;
            });
            this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
          }
          this.loading = false;
        });
        this.hospitalData = aggregateData(this.billingsFromRepo);
        this.closeAll();
      }

      if (this.roleName === 'DOCTOR' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'NURSE') {
        const headers = {
          hospitalId: this.loggedUser?.hospitalAssignedData?.id,
          month: object.month,
          year: object.year,
        };
        this.loading = true;
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
          var patientNumber = this.billingsFromRepo.length;
          this.billingSummary.cpt99453 = 0;
          this.billingSummary.cpt99454 = 0;
          this.billingSummary.cpt99457 = 0;
          this.billingSummary.cpt994581st = 0;
          this.billingSummary.cpt994582nd = 0;
          if (patientNumber > 0) {
            this.billingsFromRepo.forEach((patient, index) => {
              if (patient.cptCode99453?.status === 1) {
                this.billingSummary.cpt99453++;
              }
              if (patient.cptCode99454?.status === 1) {
                this.billingSummary.cpt99454++;
              }
              if (patient.cptCode99457?.status === 1) {
                this.billingSummary.cpt99457++;
              }
              if (patient.cptCode99458First?.status === 1) {
                this.billingSummary.cpt994581st++;
              }
              if (patient.cptCode99458Second?.status === 1) {
                this.billingSummary.cpt994582nd++;
              }

              let percent99454 = 0;
              let totalMeasurements99454 = 0;
              let measurement99454IsBillable = false;
              let measurement99457IsBillable = false;
              let measurement994581stIsBillable = false;
              let measurement994582ndIsBillable = false;
              if (patient.cptCode99454 !== null) {
                if (patient.cptCode99454.totalMeasurements < 16) {
                  percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                } else {
                  totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                  percent99454 = 100;
                  measurement99454IsBillable = true;
                }
              }

              const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

              let percent99457 = 0;
              let percent994581st = 0;
              let percent994582nd = 0;

              let seconds99457 = 0;
              let seconds994581st = 0;
              let seconds994582nd = 0;

              if (totalSec < 1200) {
                seconds99457 = totalSec;
                percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

                seconds994581st = 0;
                percent994581st = 0;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 1200 && totalSec < 2400) {
                seconds99457 = 1200;
                percent99457 = 100;
                measurement99457IsBillable = true;

                seconds994581st = totalSec - 1200;
                percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

                seconds994582nd = 0;
                percent994582nd = 0;
              }

              if (totalSec >= 2400 && totalSec < 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;
                measurement994581stIsBillable = true;

                seconds994582nd = totalSec - 2400;
                percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
              }

              if (totalSec >= 3600) {
                seconds99457 = 1200;
                percent99457 = 100;

                seconds994581st = 1200;
                percent994581st = 100;

                seconds994582nd = totalSec - 2400;
                percent994582nd = 100;
                measurement994582ndIsBillable = true;
              }

              const myBillings = {
                totalMeasurements99454: totalMeasurements99454,
                percent99454: percent99454,
                percent99457: percent99457,
                percent994581st: percent994581st,
                percent994582nd: percent994582nd,
                seconds99457: seconds99457,
                seconds994581st: seconds994581st,
                seconds994582nd: seconds994582nd,
                m99454IsBillable: measurement99454IsBillable,
                m99457IsBillable: measurement99457IsBillable,
                m994581stIsBillable: measurement994581stIsBillable,
                m994582ndIsBillable: measurement994582ndIsBillable,
              };
              patient.myBillings = myBillings;
            });
            this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
            this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
          }
          this.loading = false;
        });
        this.hospitalData = aggregateData(this.billingsFromRepo);
        this.closeAll();
      }
    },
    // aggregateData () {
    //   const aggregatedData = {};
    //   // Iterate through the data
    //   for (const entry of this.billingsFromRepo) {
    //     if (entry.id === 0) { break; } // in this case exit from loop
    //     const hospitalId = entry.hospitalData.id;
    //
    //     if (!(hospitalId in aggregatedData)) {
    //       aggregatedData[hospitalId] = { counter: 0, counter99453: 0, percent99453: 0.0, counter99454: 0, percent99454: 0.0, counter99457: 0, percent99457: 0.0, counter994581st: 0, percent994581st: 0.0, counter994582nd: 0, percent994582nd: 0.0 };
    //     }
    //
    //     aggregatedData[hospitalId].counter += 1;
    //
    //     if (entry.cptCode99453?.status === 1) {
    //       aggregatedData[hospitalId].counter99453 += 1;
    //     }
    //     if (entry.cptCode99454?.status === 1) {
    //       aggregatedData[hospitalId].counter99454 += 1;
    //     }
    //     if (entry.cptCode99457?.status === 1) {
    //       aggregatedData[hospitalId].counter99457 += 1;
    //     }
    //     if (entry.cptCode99458First?.status === 1) {
    //       aggregatedData[hospitalId].counter994581st += 1;
    //     }
    //     if (entry.cptCode99458Second?.status === 1) {
    //       aggregatedData[hospitalId].counter994582nd += 1;
    //     }
    //   }
    //
    //   // Calculate percentages after the loop
    //   for (const hospitalId in aggregatedData) {
    //     const totalCounter = aggregatedData[hospitalId].counter;
    //     if (totalCounter > 0) {
    //       aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
    //       aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
    //     }
    //   }
    //   this.hospitalData = aggregatedData;
    // },
    async expand (item) {
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
  },
};
</script>
